.add-remove-co-host-modal {
	.ant-input-search {
		margin-bottom: 24px;
		border-radius: 8px;

		.ant-input {
			margin-top: 0;
			margin-bottom: 0;
			border: none !important;
			background: none !important;
			color: black !important;
		}

		.ant-input-search-icon {
			color: black;
		}
	}

	.ant-list-item {
		&:hover {
			cursor: pointer;
		}

		.ant-list-item-meta-title {
			color: white !important;
			font-size: 24px !important;
		}

		.ant-list-item-meta-description {
			font-size: 16px !important;
		}
	}
}
