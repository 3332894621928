.ag-btn-group {
	right: 36px;
	bottom: 36px;
	height: 56px;
	position: fixed;
	z-index: 999;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.ag-btn {
	border: 0;
	width: 56px;
	height: 56px;
	outline: none;
	border-radius: 50%;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 4px 8px #00000042;
	color: #111415;
	cursor: pointer;
	margin-left: 20px;

	.icon {
		font-size: 25px;
	}
}

.ag-btn-tooltip {
	position: absolute;
	top: -40px;
	white-space: nowrap;
	left: -80px;
	padding: 3px 10px;
	color: #000;
	background-color: #fff;
	font-size: 15px;
	border-radius: 10px;
}

.ag-btn-container {
	position: relative;
	display: flex;
	align-items: center;
	width: 300px;
	justify-content: flex-end;
	margin-bottom: 20px;
}

.ag-option-label,
.ag-btn-description {
	text-align: right;
	padding: 6px 18px;
	border-radius: 11px;
	background-color: #00000080;
	min-width: 80px;
}

.ag-item-name {
	position: absolute;
	bottom: 10px;
	left: 10px;
	padding: 2px 12px;
	border-radius: 10px;
	background: rgba(0, 0, 0, 0.653);
	z-index: 1;
}

.ag-option-label::first-letter,
.ag-btn-description::first-letter {
	text-transform: capitalize;
}

.ag-btn-group-mobile .ag-btn {
	width: 46px;
	height: 46px;
	margin-right: 5px;
}

.ag-btn-group-mobile {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.ag-btn-mobile {
	position: fixed;
	right: 20px;
	bottom: 20px;
}

.ag-btn .message-icon {
	font-size: 22px;
}

@media screen and (max-width: 1024px) {
	#ag-canvas {
		grid-template-columns: repeat(2, auto);
	}
}

@media (max-width: 500px) {
	.ag-item {
		flex-basis: 50%;
		height: 120px;
		// height: 25%;
	}
	#ag-canvas-host {
		height: 20%;
	}
	.ag-canvas-host {
		height: 40%;
	}
	.ag-btn-group {
		display: none;
	}
}

@media (min-width: 500px) {
	.ag-btn-mobile {
		display: none;
	}
	.ag-btn-description {
		display: none;
	}
	.ag-btn-container {
		width: initial;
	}
}
