:root {
	--wrapper-height: calc(100% + 232px);
	--max-width: 900px;
	--card-vertical-margin: 56px;
	--card-border-radius: 8px;

	@media screen and (max-width: 800px) {
		--wrapper-height: 100%;
		--max-width: unset;
		--card-vertical-margin: 0;
		--card-border-radius: 0;
	}
}

.register-wrapper {
	width: 100vw;
	height: var(--wrapper-height);
	display: flex;
	justify-content: center;
	color: #fff;

	.register {
		position: absolute;
		margin-bottom: var(--card-vertical-margin);
		margin-top: var(--card-vertical-margin);

		&.auth {
			width: 100%;
			max-width: var(--max-width);
			box-shadow: 0px 12px 24px #0300500d;
			border-radius: var(--card-border-radius);
			padding: 38px;
			background: #000 0% 0% no-repeat padding-box;
			background-size: contain !important;
			background-repeat: no-repeat !important;
			z-index: 2 !important;

			.ant-input,
			.ant-btn,
			.ant-select,
			.ant-select-selector {
				background: #21274f !important;
			}

			h2 {
				margin-bottom: 16px;
			}

			a {
				color: white !important;
				transition: all 0.5s;

				&:hover {
					opacity: 0.8 !important;
				}
			}
		}

		&-form {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;

			h2 {
				margin-top: 16px;
			}

			.ant-row {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
			}

			.ant-form-item-label {
				text-align: left;

				> label {
					color: #fff;
					font: 16px/21px Roboto;
					letter-spacing: 0px;

					&:after {
						content: "";
					}
				}
			}

			.ant-checkbox-wrapper {
				color: #fff;
			}

			.ant-form-item-has-error
				.ant-input:not(.ant-form-item-has-error .ant-input-disabled) {
				background: #20254d !important;
			}

			.ant-select-selector {
				padding-top: 6.5px;
				height: 48px !important;
			}

			.remember_me {
				.ant-form-item-control-input-content {
					display: flex;
					justify-content: space-between;
				}

				a {
					text-decoration: underline;
				}
			}
		}
	}
}

.register-background-layer {
	width: 100vw;
	height: 100vh;
	position: fixed;
	background-position: center !important;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	z-index: -1;
}

.ant-btn:not(.fullwidth) {
	max-width: 256px;
}

.ant-btn > span {
	color: white !important;
}
