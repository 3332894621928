.agenda {
    .content {
        margin: 0 var(--horizontal-margin);

        .ant-picker-calendar {
            background: #424242;
            color: white;
            border-top-left-radius: 12px !important;
            border-top-right-radius: 12px !important;
            margin-bottom: 46px;

            .ant-picker-date-panel {
                background: #212121;
            }

            .ant-picker-body {
                background: #424242;
                border-bottom-left-radius: 12px !important;
                border-bottom-right-radius: 12px !important;

                .ant-picker-calendar-date-value {
                    color: #aaaaaa;
                }

                .ant-picker-cell-in-view {
                    .ant-picker-calendar-date-value {
                        color: white;
                        font-weight: bolder;
                    }
                }

                .ant-picker-cell-inner {
                    background: transparent !important;
                }

                td {
                    transition: all 0.25s;

                    &:hover {
                        transition: all 0.25s;
                        background: #303030 !important;
                    }
                }

                th {
                    color: white;
                    font-weight: bolder;
                }

                span {
                    color: white;
                }

                th {
                    font-size: 16px;
                }

                .ant-picker-calendar-date-value {
                    font-size: 20px;
                }

                .ant-picker-calendar-date-today {
                    background: #303030;
                }
            }

            .ant-select {
                margin-right: 16px;
            }

            .ant-select-dropdown {
                z-index: 9999;
            }

            .ant-picker-calendar-mode-switch {
                display: none;
            }

            ul {
                margin: 0 !important;
                padding: 0 !important;
            }
        }
    }
}
