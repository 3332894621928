@import "normalize";
@import "mixins";
@import url("https://fonts.googleapis.com/css?family=Exo:300,500,700&subset=latin");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
@import "./materialdesignicons.min.css";

html,
body {
	width: 100%;
	height: 100%;
	font-family: Roboto;
	font-size: 16px;
}

body {
	background: #000;
}

#root {
	color: #fff;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: unset !important;
	justify-content: center;
}

h2,
h4 {
	color: #fff;
}

h2 {
	color: #fff;
	margin: 0;
	font-size: 36px;
	font-family: Exo;
	font-size: 48px;
	letter-spacing: 0px;
}

a {
	color: white;

	&:hover {
		color: rgba(255, 255, 255, 0.8);
	}
}

.modal {
	.ant-modal-content,
	.ant-modal-header {
		background: #424242;
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
	}

	.ant-modal-header {
		border-bottom: 1px #ababab solid;
	}

	.ant-modal-footer {
		background: #424242;
		border-bottom-left-radius: 16px;
		border-bottom-right-radius: 16px;
		border: none;
		border-top: 1px #ababab solid;
	}

	.ant-modal-title {
		color: white;
	}

	span {
		color: white;
	}

	.ant-modal-body {
		background-color: #424242;
		color: #fff;

		.ant-avatar {
			margin-bottom: 16px;
			box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.22);
		}

		h3 {
			font-size: 24px;
		}

		h5 {
			font-size: 20px;
		}

		.ant-input {
			margin-top: 8px;
			margin-bottom: 8px;
			background: white;
			color: black;
		}

		.ant-btn {
			height: 42px !important;
			width: auto;
			max-width: unset !important;
			font-size: 14px;
			margin-top: 0px !important;
			margin-bottom: 24px;
		}
	}

	.ant-modal-footer {
		.ant-btn {
			height: 42px !important;
			width: unset !important;
			background: #121212;

			> span {
				color: #ffffff !important;
			}

			&-primary {
				background: #005586;

				> span {
					color: white !important;
				}
			}
		}
	}
}

.ant-modal-content {
	border-radius: 16px;
}

.ant-modal-body {
	background-color: #2c3339;
	color: #fff;
	border-radius: 16px;
}

.ant-modal-confirm {
	&-body {
		.ant-modal-confirm-title {
			color: #fff;
			font-size: 24px;
		}

		.ant-modal-confirm-content {
			color: #d4d4d4;
			font-size: 16px;
		}
	}

	.ant-modal-confirm-btns {
		display: flex;
		justify-content: center;
		float: none;

		.ant-btn {
			height: 35px;
			width: 160px;
		}
	}
}

.ant-input,
.ant-select,
.ant-select-selector,
.ant-picker {
	background: #40474d 0% 0% no-repeat padding-box !important;
	border: 1px solid #535e66 !important;
	border-radius: 8px !important;
	line-height: 48px !important;
	color: #fff !important;

	input {
		color: white;
	}
}

.ant-picker-input:not(.fullwidth) {
	height: 32px;
}

.ant-input {
	height: 48px !important;
}

.ant-picker-cell-inner {
	color: white;
}

.ant-select-arrow,
.ant-picker-header-view,
.ant-picker-super-next-icon,
.ant-picker-header-super-prev-btn {
	color: white !important;
}

.ant-select-dropdown,
.ant-picker-panel-container {
	background: #40474d 0% 0% no-repeat padding-box !important;
	border: 1px solid #535e66 !important;
	border-radius: 8px !important;
}

.ant-picker-year-panel {
	* {
		color: white !important;
	}
}

.ant-picker-datetime-panel {
	* {
		color: white !important;
	}

	.ant-picker-cell {
		* {
			color: #cccccc !important;
		}
	}

	.ant-picker-cell-in-view {
		* {
			color: #ffffff !important;
		}
	}
}

.ant-picker-time-panel-cell-inner {
	color: white !important;
	transition: all 0.25s !important;

	&:hover {
		transition: all 0.25s;
		background: #212121 !important;
	}
}

.ant-picker-panel {
	border: none;
}

.ant-picker-now {
	margin-top: 8px;
}

.ant-picker-clear {
	background: transparent !important;
}

.ant-picker-footer {
	border-top: 1px solid #ffffff !important;
}

.ant-picker-time-panel-cell-selected > .ant-picker-time-panel-cell-inner {
	background: #212121 !important;
}

.ant-select-item-option-disabled {
	opacity: 0.7;
	user-select: none;
}

.ant-select-item-option {
	background: #40474d 0% 0% no-repeat padding-box !important;
	color: white !important;
}

.ant-select-selection-item {
	background: transparent !important;
	border-radius: 8px !important;

	> span {
		color: white !important;
	}
}

.ant-form-item-has-error {
	.ant-input,
	.ant-input:hover {
		background: #40474d 0% 0% no-repeat padding-box;
	}
}

.ant-btn {
	width: 100%;
	background: #343434 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 4px #00000042;
	border-color: transparentize($color: #00000042, $amount: 0.3);
	border-radius: 8px;
	height: 48px;
	text-transform: uppercase;
	letter-spacing: 1px;

	&:hover,
	&:active,
	&:focus {
		background: #212121;
	}

	&[disabled] {
		background: #686868;
		color: #ffffff;

		&:hover {
			background: #686868;
			color: #ffffff;
		}
	}
}

.ant-checkbox {
	&-inner {
		border-color: #343434;
	}

	&.ant-checkbox-checked {
		.ant-checkbox-inner {
			background-color: #343434;
			border-color: #343434;
		}
	}
}

.ant-tooltip {
	&-arrow {
		display: none;
	}

	&-inner {
		background: #212121;
		box-shadow: 0px 12px 24px #00000042;
		border-radius: 8px;
		padding: 22px;
	}

	&-header {
		color: #fff;
		font-size: 24px;
		font-weight: bold;
		margin-bottom: 8px;
	}

	&-menu {
		padding: 0;
		margin: 0;
		list-style: none;

		li:not(:last-of-type) {
			margin-top: 16px;
			margin-bottom: 16px;
		}

		&-item-container {
			display: flex;
			align-items: center;
		}

		a {
			line-height: 50px;
			display: flex;
			align-items: center;
			color: #fff;
		}

		i {
			font-size: 24px;
			margin-right: 8px;
		}
	}
}

.Toastify__toast-container {
	margin-top: 72px !important;
}

.Toastify__toast-container > div {
	border-radius: 8px !important;
}

.Toastify__toast-body {
	color: #121212;
}

.hoverable:hover {
	cursor: pointer;
	text-decoration: underline;
}
