.helpdesk-ticket-list {
    width: 100%;
    height: 100%;
    display: block;

    h2 {
        margin-top: 36px;
    }

    ul {
        list-style: none;
        margin: 42px;
        padding: 0;
    }
}