.stand-edit {
    width: 100vw;
    height: 100vh;
    display: block;

    .form-wrapper {
        padding-top: 32px;
        padding-left: var(--horizontal-margin);
        padding-right: var(--horizontal-margin);

        label {
            display: block;
        }

        .ant-card {
            border: none !important;
            background: #424242;

            .ant-card-cover {
                height: 256px !important;

                img {
                    height: 100%;
                    object-fit: cover;
                }
            }

            .ant-card-meta-title {
                color: #ffffff;
            }

            .ant-card-meta-description {
                color: #cccccc;
            }
        }

        .ant-input {
            margin-top: 4px !important;
            margin-bottom: 16px;
        }

        .products {
            .product {
                margin-top: 16px;
            }

            .gutter-row {
                width: 100%;

                .ant-card-cover {
                    height: 256px !important;

                    img {
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        .ant-btn {
            margin-top: 8px;

            &.update-btn {
                margin-top: 72px;
                margin-bottom: 56px;
            }

            &.view-stand-btn {
                margin-left: 16px;
                margin-bottom: 56px;
            }
        }
    }
}
