.modal {
    .message {
        font-size: 22px;
        margin-bottom: 16px;
    }

    textarea {
        height: 80px !important;
        margin-bottom: 16px !important;
        resize: none !important;
    }
}
