.agora-webcall {
	display: block;
	width: 100%;
	height: 100%;

	#no-streams {
		width: 100%;
		height: calc(100% - 72px * 4);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.icon {
			font-size: 142px;
		}

		.title {
			font-size: 32px;
			margin: 0;
			color: white;
			align-self: center;
		}
	}

	.attendees-count {
		position: absolute;
		display: flex;
		align-items: center;
		top: 8px;
		left: 8px;
		background: black;
		font-size: 14px;
		color: white;
		border-radius: 8px;
		padding: 4px 12px;
		z-index: 999;
		user-select: none;
		-webkit-user-select: none;

		.red-dot {
			display: inline-block;
			width: 10px;
			height: 10px;
			background: red;
			border-radius: 50%;
			margin-right: 8px;
		}

		.icon {
			margin-left: 10px;
			margin-right: 2px;
		}
	}

	.screen-share-container {
		position: relative;
		width: 100%;
		height: calc(calc(100% - 72px) - 200px);

		div {
			width: 100%;
			height: 100%;
		}
	}

	.talking-active {
		border: 2px solid yellowgreen;
	}

	.profile-pic-wrapper {
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		z-index: 4;
		opacity: 0;
		transition: opacity 0.25s;
		background: #424242;
		pointer-events: none;

		&.muted {
			pointer-events: all;
			opacity: 1;
		}

		img {
			width: 156px;
			height: 156px;
			border-radius: 50%;
		}
	}

	.normal-size-streams-container {
		display: grid;
		min-height: calc(100% - 72px);
		grid-auto-rows: auto;
		grid-template-columns: repeat(2, 1fr);

		&.with-screensharing {
			min-height: unset;

			.stream-container {
				min-height: 200px;

				.stream > * {
					min-height: 200px !important;
				}
			}
		}

		.stream-container {
			position: relative;
			min-height: 400px;

			.stream {
				width: 100%;
				height: 100%;
				z-index: 1;

				> * {
					width: 100%;
					height: 100%;
					min-height: 400px;
				}
			}

			.stream-name {
				display: flex;
				align-items: center;
				font-size: 16px;
				position: absolute;
				bottom: 8px;
				left: 8px;
				z-index: 5;
				background: rgba(0, 0, 0, 0.7);
				border-radius: 12px;
				color: white;
				padding: 8px 12px;

				.icon {
					font-size: 20px;
					margin-right: 8px;
				}
			}
		}
	}
}

:-webkit-full-screen {
	width: 70vw;
	height: 70vh;
}
