.monitor {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: black;
  position: relative;

  iframe {
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}