.list-users {
	width: 100vw;
	height: 100vh;

	.content {
		margin: 0 var(--horizontal-margin);

		.empty-state {
			text-align: center;
			color: white;

			.icon {
				font-size: 78px;
			}

			span {
				font-size: 32px;
			}
		}

		.title {
			margin-top: 72px;
			color: white;
			font-weight: bolder;
			font-size: 56px;
		}

		.icon {
			color: white;
			margin-right: 8px;
		}

		.email,
		.website {
			color: #212121;

			.icon {
				color: #212121 !important;
			}
		}

		.participant-type,
		.stand-title,
		.private-chat {
			display: inline;
			color: #212121;

			.icon {
				color: #212121 !important;
			}
		}

		.ant-input-search {
			margin-bottom: 36px;
			border-radius: 8px;

			.ant-input {
				border: none !important;
				background: none !important;
				color: black !important;
			}
		}
	}
}
