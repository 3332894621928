:root {
	--hero-title: 5vw;
	--stand-video-height: 600px;

	@media screen and (max-width: 800px) {
		--hero-title: 7vw;
		--stand-video-height: 400px;
	}

	@media screen and (max-width: 600px) {
		--hero-title: 8vw;
		--stand-video-height: 360px;
	}

	@media screen and (max-width: 400px) {
		--hero-title: 9vw;
		--stand-video-height: 320px;
	}
}

.stand {
	width: 100vw;
	height: 100vh;
	display: block;
	z-index: 2 !important;
	overflow-x: hidden;
	background: #212121;

	.back {
		position: fixed;
		top: 16px;
		left: 16px;
		height: 56px;
		z-index: 3;
		background: rgba(0, 0, 0, 0.32);
		border-radius: 8px;
		padding: 16px;
		color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.5s;

		&:hover {
			background: black;
		}

		span {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 18px;
			font-weight: bold;

			i {
				font-size: 24px;
				margin-right: 8px;
			}
		}
	}

	.hero {
		width: 100vw;
		height: 80vh;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		background-size: cover !important;
		background-position: center !important;
		background-repeat: no-repeat !important;
		text-align: center !important;

		h1 {
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 2;
			color: white;
			font-weight: bolder;
			font-size: var(--hero-title);
			margin: 8px;
			margin-left: var(--horizontal-margin) !important;
			margin-right: var(--horizontal-margin) !important;
			font-family: Exo;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.ant-btn {
			width: 300px;
			background: white;
			display: flex;
			align-items: center;
			justify-content: center;

			> span {
				color: unset !important;
			}

			i {
				font-size: 22px;
				margin-right: 8px;
			}
		}
	}

	.description {
		margin-left: var(--horizontal-margin) !important;
		margin-right: var(--horizontal-margin) !important;
		text-align: center;
		font-size: 20px;
		color: #fcfcfc;
		margin-top: 56px;
	}

	.video {
		width: calc(100vw - calc(var(--horizontal-margin) * 2));
		height: var(--stand-video-height);
		margin: 56px var(--horizontal-margin) !important;
		text-align: center;
	}

	.products {
		margin-left: var(--horizontal-margin) !important;
		margin-right: var(--horizontal-margin) !important;

		.ant-card {
			border-radius: 8px;
			border: none !important;
			background: #424242;

			.ant-card-cover {
				height: 278px !important;

				img {
					border-top-left-radius: 8px;
					border-top-right-radius: 8px;
					height: 100%;
					object-fit: cover;
				}
			}

			.ant-card-meta-title {
				color: #ffffff;
			}

			.ant-card-meta-description {
				color: #cccccc;
			}
		}
	}

	h2 {
		color: white;
		margin-top: 56px;
		margin-left: calc(var(--horizontal-margin) + 8px) !important;
		margin-right: var(--horizontal-margin) !important;
	}

	h5 {
		color: #bbbbbb;
		margin-top: 8px;
		margin-left: calc(var(--horizontal-margin) + 8px) !important;
		margin-right: var(--horizontal-margin) !important;
		font-size: 24px;

		a {
			color: #ffffff;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.contact {
		margin-top: 56px;
		margin-left: var(--horizontal-margin) !important;
		margin-right: var(--horizontal-margin) !important;

		.maps-wrapper {
			position: relative;
			height: 400px;
			border-radius: 8px;
			overflow: hidden;
			box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.32);
		}

		.ant-form-item {
			flex-direction: column;

			.ant-form-item-label {
				text-align: left;

				> label {
					color: #ffffff !important;
				}

				> label.ant-form-item-required::before {
					display: none;
				}
			}
		}

		textarea {
			height: 192px !important;
			resize: none;
		}
	}

	.calendar-refresh {
		color: #bbbbbb;
		margin-top: 56px;
		margin-left: var(--horizontal-margin);
		margin-right: var(--horizontal-margin);
	}

	.sponsors {
		width: 100vw;
		height: 72px;
		position: fixed;
		z-index: 3;
		left: 0;
		bottom: 0;
		margin: 0;
		padding: 4px;
		box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.32);
		background: #424242;
		white-space: nowrap;
		overflow-x: scroll;
		text-align: center;

		li {
			display: inline;

			img {
				width: auto;
				height: 100%;
				margin-left: 4px;
				margin-right: 4px;
			}
		}
	}
}
