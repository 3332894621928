.event-item {

  &__name {
    font-weight: bolder;
    letter-spacing: 0px;
    margin-top: 16px;
  }

  &__owner {
    font-weight: bolder;
    letter-spacing: 0px;
    font: 16px/21px Roboto;
  }

  &__description_title {
    color: white;
    font-weight: 500 !important;
    margin-top: 8px;
    font: 18px/24px Roboto;
  }

  &__description {
    font: 18px/24px Roboto;
  }

  &__time {
    font: 16px/21px Roboto;
    font-size: 28px;
    color: #FFFFFF;
    margin: 42px 0 42px 0;
    font-weight: bold;
    line-height: 1.4;
  }

  &__logo {
    width: 100%;
    height: 320px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.event-item > *:not(.event-item__logo) {
  margin-left: 32px;
  margin-right: 32px;
}