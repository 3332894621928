.room {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	z-index: 2 !important;

	&.main {
		overflow: hidden;
	}

	&.webinar {
		display: flex;
		flex-direction: row;
	}
}

.room-background-layer {
	width: 100vw;
	height: 100vh;
	position: fixed;
	background-position: bottom !important;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	z-index: -1 !important;
}

.webinar {
	.workspace {
		display: flex;
		height: 100%;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
		z-index: 2 !important;
		/* padding: 1%; */
		position: relative;
		/* max-width: 1280px; */
		margin: 0 auto;
	}

	.workspace.fullscreen {
		align-items: unset !important;
		overflow: hidden !important;
	}

	&-participants {
		max-width: 1280px;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		margin-top: 8px;
	}

	.monitor {
		max-width: 100vw;
		height: calc(100% - 72px);
		top: 0;
	}

	.monitor-casual {
		flex: 0 0 calc(25% - 6px);
		box-sizing: border-box;
		min-height: 176px;
		margin: 0 8px 8px 0;

		&:nth-child(4n) {
			margin-right: 0;
		}
	}

	.participants {
		width: 100%;
		height: 90vh;
		display: flex;
		flex-wrap: wrap;
	}

	.participant_admins {
		width: 100%;
		height: 90vh;
		display: flex;
		flex-wrap: wrap;
	}

	.participant {
		width: 24.5%;
		height: 20%;
		background-color: #eee;
		margin-top: 5px;
	}

	.participant:nth-child(8) {
		margin-right: 0;
	}

	.participant:nth-child(13) {
		margin-right: 0;
	}

	.participant_admin {
		width: 33%;
		height: 35%;
		background-color: #eee;
		margin-top: 5px;
	}

	.participant_admin:nth-child(1) {
	}

	.participant_admin:nth-child(3n) {
	}

	.room {
		z-index: 2 !important;

		&-workspace {
			flex: 1 1 auto;
			position: relative;
		}

		&-chat {
			z-index: 999 !important;
			transition: all 0.75s;
			width: 0;
			height: 100%;
		}
	}

	&.chat-opened {
		z-index: 999 !important;
		transition: all 0.75s;

		.room-chat {
			z-index: 999 !important;
			width: 28%;
			transition: all 0.75s;
		}

		.monitor {
			max-width: 100%;
		}
	}
}

video[id$="-screenshare"] {
	transform: none !important;
}

@media screen and (max-width: 1024px) {
	.webinar.chat-opened {
		.room-chat {
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			width: 100%;
			z-index: 999 !important;
		}
	}
}
