.chat-message {
	display: flex;
	padding: 7px 20px;
	overflow: auto;

	.avatar {
		margin-right: 20px;
	}

	&__body {
		.author {
			color: #ffffffcc;
		}

		.message {
			color: #fff;
		}

		.timestamp {
			color: #ffffff80;
		}
	}
}
