.chat-conversations {
    width: 100vw;
    height: 100vh;
    background: #212121;

    .ag-btn-container {
        position: fixed;
        margin: 0 !important;
        bottom: 24px;
        right: 24px;

        .ag-btn {
            margin: 0 !important;

            i {
                color: black !important;
            }
        }
    }

    .content {
        margin: 32px var(--horizontal-margin);

        .ant-list-item-meta-title {
            font-size: 32px;
        }

        .ant-list-item-meta-description {
            font-size: 24px;
        }

        .ant-avatar-image {
            margin-top: 16px;
        }

        .ant-empty-description {
            color: white;
        }
    }
}
