.verify-account {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #424242;
	padding-left: var(--horizontal-margin);
	padding-right: var(--horizontal-margin);

	.ant-input,
	.ant-btn {
		background: #21274f !important;
	}

	.message {
		font-size: 24px;
		color: white;
	}
}
