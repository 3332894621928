.event {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 2 !important;

  .navbar-wrapper {
    position: fixed;
    width: 100vw;
    z-index: 998;
  }

  .workspace {
    display: flex;
    height: 100%;
    justify-content: center;
    z-index: 2 !important;

    &-aside {
      background-color: #111315;
      box-sizing: border-box;
      overflow: hidden auto;
      width: 554px;
      background-size: cover !important;
      background-position: bottom !important;
      z-index: 2 !important;
      box-shadow: 0 0 88px 0 rgba(0, 0, 0, 0.65);
      user-select: none;

      .event-item {
        /* padding: 20px 64px; */
      }

      &__list {
        font-size: 16px;
        border-top: 1px solid rgba(255, 255, 255, 0.33);
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 16px;
        padding-top: 16px;
        display: block;

        &-header {
          margin-top: 16px;
          margin-bottom: 16px;
          font-size: 24px;
          font-weight: bold;
          color: #FFFFFF;
        }

        &-body {
          display: block;

          * {
            display: flex;
            line-height: 48px;
            color: #ffffff;
            justify-content: space-between;
            align-items: center;
            transition: all 0.2s;

            &:hover {
              opacity: 0.5;
              cursor: pointer;
            }
          }
        }
      }
    }

    &-content {
      background-color: #1b1e22;
      flex: 1 1 auto;

      .stub {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-size: cover !important;
        background-position: center !important;

        &-shape {
          max-height: 512px;
          max-width: 682px;
          background: no-repeat url("../../../assets/svg/illustration.svg") center center;
          border-radius: 50%;
          margin-bottom: 38px;
          width: 100%;
          height: 100%;
        }

        &-text {
          font: Bold 24px/24px Exo;
          font-weight: bold;
          letter-spacing: 0.58px;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .workspace {
      z-index: 2 !important;

      &-content {
        display: none;
      }

      &-aside {
        padding: 0;
        width: 100% !important;
      }
    }
  }
}
