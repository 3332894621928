:root {
	--card-height: unset;
	--card-border-radius: 8px;

	@media screen and (max-width: 800px) {
		--card-height: 100%;
		--card-border-radius: 0;
	}
}

.login-background-layer {
	width: 100vw;
	height: 100vh;
	position: fixed;
	background-position: center !important;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	z-index: -1;
}

.login-wrapper {
	width: 100vw;
	height: 100vh;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;

	.login {
		flex: 1 1 auto;
		display: flex;
		flex-direction: row;
		align-self: center;
		border-radius: var(--card-border-radius);
		width: 100%;
		height: var(--card-height);
		max-width: 900px;
		min-height: 650px;

		.form {
			display: flex;
			flex: 1 1 auto;
			flex-direction: column;

			h2 {
				margin-bottom: 24px;
			}

			.ant-row {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
			}

			.ant-form-item-label {
				text-align: left;

				> label {
					color: #fff;
					font: 16px/21px Roboto;
					letter-spacing: 0px;

					&:after {
						content: "";
					}

					&.ant-form-item-required::before {
						display: none;
					}
				}
			}

			.ant-checkbox-wrapper {
				color: #fff;
			}
		}

		&.auth {
			position: relative;
			width: 100%;
			overflow: auto;
			box-shadow: 0px 12px 24px #0300500d;
			padding: 38px;
			background: #000 0% 0% no-repeat padding-box;
			background-size: contain !important;
			background-repeat: no-repeat !important;
			z-index: 2 !important;
		}

		a {
			color: white !important;
			transition: all 0.5s;
		}

		a:hover {
			opacity: 0.8 !important;
		}

		&-event,
		&-form {
			flex: 1 1 50%;
			background-repeat: no-repeat !important;
			background-position: center !important;
			background-size: contain !important;
		}

		&-event {
			@media screen and (max-width: 800px) {
				display: none;
			}
		}

		&-separator {
			border: 1px solid #868686;
			margin: 0 38px;

			@media screen and (max-width: 800px) {
				display: none;
			}

			border: 0.8px solid rgba(255, 255, 255, 0.33);
			margin: 0 38px;
		}

		&-form {
			align-self: center;
			background-size: cover !important;

			h2 {
				margin-top: 16px;
			}

			.ant-input,
			.ant-btn {
				background: #21274f !important;
			}

			.ant-form-item-has-error
				.ant-input:not(.ant-form-item-has-error .ant-input-disabled) {
				background: #20254d !important;
			}

			.remember_me {
				.ant-form-item-control-input-content {
					display: flex;
					justify-content: space-between;
				}
			}
		}

		.ant-btn:not(.fullwidth) {
			max-width: 256px;
		}

		.ant-btn > span {
			color: white !important;
		}

		.success {
			align-items: center;
			justify-content: center;
		}

		@media screen and (max-width: 1024px) {
			border-radius: 0;
			align-self: unset;
			flex-direction: column;
			overflow: visible;
			max-width: unset;

			&-form {
				align-self: unset !important;
			}

			&-separator {
				display: none;
			}

			&-event {
				display: none;
			}
		}
	}
}
