.admin {
	display: block;
	width: 100vw;
	height: 100vh;

	.remove-room,
	.remove-user {
		font-size: 32px;
		margin-left: 10px;
	}

	.options-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: calc(100vh - 72px);
		border: 1px solid rgba(255, 255, 255, 0.7);

		.options {
			width: 100vw;
			height: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr;

			@media screen and (max-width: 800px) {
				grid-template-columns: unset !important;
			}

			.option-container {
				display: flex;
				height: 100%;
				width: 100%;
				border: 1px solid rgba(255, 255, 255, 0.7);
				align-items: center;
				justify-content: center;
				font-size: 48px;
				user-select: none;
				transition: 0.2s all;
				padding-left: 16px;
				padding-right: 16px;

				@media screen and (max-width: 800px) {
					font-size: 36px;
				}

				i {
					font-size: 72px;
					margin: 32px;
				}
			}

			.option-container:hover {
				transition: 0.2s all;
				background: #d4d4d4;
				color: black;
				cursor: pointer;
			}
		}
	}

	.create-conference-form,
	.create-room-form {
		padding: 32px;
	}

	.table-wrapper {
		padding: 32px;

		.ant-table-content {
			border-radius: 8px;
		}

		.ant-table {
			background: #212121;
			color: white;

			tr,
			thead > tr > th {
				background: #424242;
				color: white;
			}

			td,
			td:hover tr,
			tr:hover,
			.ant-table-filter-trigger,
			.ant-table-filter-trigger:hover,
			.ant-table-filter-trigger-container:hover,
			.ant-table-cell:hover,
			.ant-table-column-sort {
				transition: all 0.5s !important;
				background: #424242 !important;
			}

			.ant-table-filter-column-title:hover {
				transition: all 0.5s !important;
				background: #212121;
			}

			.ant-empty-description {
				color: white;
			}

			.ant-dropdown-trigger:hover,
			.ant-dropdown-trigger:active {
				background: #212121 !important;
			}

			span {
				color: white;
			}

			.modified {
				user-select: none;
			}

			.disabled-checkbox {
				opacity: 0.65;

				.ant-checkbox-inner::after {
					border-color: unset;
				}

				&:hover {
					cursor: cross;
				}
			}
		}
	}

	.-btn {
		color: white !important;
	}

	.upload-button {
		width: 300px;
		float: right;
		color: white;
		margin-bottom: 32px;
		z-index: 3;

		&:focus,
		&:active,
		&:hover {
			background: unset !important;
		}
	}

	.form {
		padding: 0 var(--horizontal-margin);

		.ant-row {
			flex-direction: column;

			.ant-form-item-label {
				text-align: left;

				> label {
					color: white !important;
				}
			}
		}
	}
}

.ant-table-filter-dropdown {
	background: #424242 !important;
}

.ant-table-body {
	overflow-x: auto !important;
}
