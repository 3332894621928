@import "../../../assets/scss/mixins.scss";

.navbar {
	position: relative;
	background: #2c3339;
	box-shadow: 0px 2px 4px #00000042;
	box-sizing: border-box;
	width: 100%;
	height: 72px;
	display: flex;
	flex: 0 0 auto;
	align-items: center;
	padding: 0 64px;
	font-size: 20px;
	z-index: 2 !important;
	background-size: cover !important;
	background-position: center !important;

	@media screen and (max-width: 800px) {
		padding: 0;
		padding-left: 16px;
		padding-right: 16px;
	}

	.icon {
		color: #fff;
		width: 24px;
	}

	&__title {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		user-select: none;

		@media screen and (max-width: 500px) {
			max-width: 140px;
		}
	}

	&__back {
		@include centered;
		width: 40px;
		height: 40px;
		margin-right: 20px;
		cursor: pointer;
		transition: all 0.2s;
		font-size: 20px;

		&:hover {
			opacity: 0.5;
		}
	}

	&__actions {
		flex: 1 1 auto;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.action {
			@include centered;
			cursor: pointer;
			width: 46px;
			height: 46px;
			font-size: 24px;
			margin-right: 16px;
			transition: 0.2s all;
			border-radius: 50%;
			background: transparent;

			&:last-of-type {
				margin-right: 0px;
			}

			&:hover {
				opacity: 0.5;
				background-color: rgba(255, 255, 255, 0.1);
			}

			.action-profile-image {
				width: 32px;
				height: 32px;
				border-radius: 50%;
				border: 1px solid white;
			}
		}
	}

	&-action-tooltip {
		min-width: 240px;
	}

	&-notification-tooltip {
		.ant-tooltip-inner {
			width: 472px;
		}
	}

	.video-source {
		margin-right: 10px;
		text-transform: uppercase;
		font-weight: bold;
		font-family: "Roboto", sans-serif;
		cursor: pointer;
		background: none;
		outline: none;
		border: none;
	}
}

.recording-modal {
	width: 600px !important;
	max-width: 90%;

	.ant-modal-confirm-content {
		margin-left: 0 !important;

		img {
			border-radius: 8px;
			margin-bottom: 12px;
		}
	}
}

iframe.whiteboard {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 4;
	background: rgba(255, 255, 255, 0.75);
}

@media (max-width: 500px) {
	.navbar__actions .chat-icon {
		display: none;
	}
}
