.chat {
	position: fixed;
	background: #111315;
	display: flex;
	flex-direction: column;
	height: 100%;
	z-index: 1;
	width: 28%;
	height: 100%;
	transition: all 0.5s;

	&.fullwidth {
		height: calc(100vh - 72px);
		width: 100% !important;
	}

	&-messages {
		background-size: cover !important;
		background-repeat: no-repeat !important;
		word-break: break-word;
	}

	&-header {
		height: 72px;
		font-size: 20px;
		display: flex;
		flex: 0 0 auto;
		align-items: center;
		letter-spacing: 0.48px;
		border-bottom: 1px solid #40474d;
		box-sizing: border-box;
		padding: 0 20px;

		button {
			background: none;
			outline: 0;
			border: 0;
			padding: 0;
			width: 40px;
			height: 40px;
			cursor: pointer;
			margin-right: 20px;
		}

		i {
			color: #fff;
		}
	}

	&-messages {
		flex: 1 1 auto;
		padding: 20px 0;
		overflow: auto;
	}

	&-moderation-notice {
		flex: 0 0 auto;
		color: white;
		background: black;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 16px;
		text-align: center;
	}

	&-footer {
		width: 100%;
		position: relative;
		height: 72px;
		background: #2c3339;
		padding: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 var(--horizontal-margin);

		.ant-btn {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			background: white;

			&[disabled] {
				color: #ababab;
			}
		}
	}

	.avatar {
		border-radius: 50%;
	}
}

.chat-notice {
	color: white;
	font-size: 26px;
	text-align: center;
}

.comment-action {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0 16px;
}

.comment-action .chat-input {
	width: 100% !important;
	padding-left: 16px;
	padding-right: 16px;
}

@media (max-width: 1000px) {
	.chat {
		width: 100%;
		transition: none;
	}
}
